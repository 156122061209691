<template>
  <div class="visitor-chats" v-if="messageModes.includes('chat')">
    <chat-log :visitor-id="visitorId" :visitor="visitor" class="w-full" :maxHeight="screenHeight" :is-inside-modal="true"></chat-log>
    <div class="visitor-chats-tools flex padding-2">
      <div class="flex flex-row" style="justify-content: flex-start; flex-grow: 1">
        <div class="visitor-chats-tools-button">
          <clip-icon class="visitor-chats-container-icon" :width="20" :height="20"></clip-icon>
        </div>
      </div>
      <div class="flex flex-row" style="justify-content: flex-end; flex-grow: 1">
        <div class="visitor-chats-tools-button">
          <more-icon class="visitor-chats-container-icon" :width="20" :height="20"></more-icon>
        </div>
      </div>
    </div>

    <div v-if="canChatWithVisitor" class="visitor-chats-container flex padding-2">
      <send-chat-message :visitor-id="visitorId" :visitor="visitor" :is-modal="true"></send-chat-message>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
import { mapGetters } from 'vuex'
import ChatLog from './ChatLog.vue'
import ClipIcon from '../../../components/icons/ClipIcon.vue'
import MoreIcon from '../../../components/icons/MoreIcon.vue'
import SendChatMessage from './SendChatMessage.vue'

const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}
export default {
  components: {
    ClipIcon,
    MoreIcon,

    ChatLog,
    SendChatMessage
  },
  props: {
    visitorId: {
      type: String,
      required: true
    },
    visitor: {
      type: Object,
      required: true
    },
    messageModes: {
      type: Array,
      required: true
    },
    screen: {
      type: String,
      required: false,
      default: SCREEN.MINIMIZED
    }
  },
  data() {
    return {
      typedMessage: '',
      SCREEN
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    agentName() {
      let name = ''
      if (this.activeUserInfo.firstname) {
        name = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          name = `${name} ${this.activeUserInfo.lastname}`
        }
      } else if (this.activeUserInfo.displayName) {
        name = this.activeUserInfo.displayName
      }
      return name
    },
    canChatWithVisitor() {
      return this.visitor && this.visitor.connectedAgentId && this.activeUserInfo.uid === this.visitor.connectedAgentId && this.visitor.type === 'embed'
    },
    screenHeight() {
      return this.screen === this.SCREEN.MINIMIZED ? 160 : 360
    }
  },
  methods: {
    async sendChatMessage() {
      const chatMessage = this.typedMessage.trim()
      this.typedMessage = ''
      if (chatMessage.length > 0) {
        const visitorRef = this.$db.collection('visitors').doc(this.visitorId)
        const message = {
          message: chatMessage,
          time: new Date(),
          chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
          agentName: this.agentName,
          agentId: this.activeUserInfo.uid,
          sender: 'agent'
        }
        await visitorRef.update({
          chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
          callTypeState: 'agent-chatting',
          agentMessages: this.$firebase.firestore.FieldValue.increment(1),
          visitorMessages: this.$firebase.firestore.FieldValue.delete()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visitor-chats {
  .padding-2 {
    padding: 0.5rem !important;
  }
  width: auto;
  height: 100%;
  background: white;
  border-radius: 6px;

  &-tools {
    background-color: rgba(var(--vs-primary), 1) !important;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    &-button {
      width: auto;
      height: 30px;
      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 0.1s;
      margin-left: 1.2rem;
      margin-right: 1.5rem;
    }
    &-button:hover {
      margin-top: -2px;
    }
  }

  &-container {
    &-input {
      font-family: 'Lato';
      border: none;
      width: 100%;
      padding: 5px;
      font-size: 15px;
      line-height: 19px;
      color: #12598d;
    }
    &-input::placeholder {
      color: #12598d;
      opacity: 0.5;
    }
    &-button {
      width: 106px;
      height: 35px;
      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 1s;
      .pa-icon-disabled {
        color: rgba(0, 0, 0, 0.2) !important;
        path {
          stroke: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
    &-button:hover {
      background: rgba(var(--vs-secondary), 0.2) !important;
    }
  }
}
</style>
