<template>
  <div class="flex flex-col justify-center audio-video-settings">
    <div>
      <div class="mt-2">
        <span>{{ $t('vue.microphoneAndLoudspeaker') }}</span>
      </div>

      <div class="mt-2 flex flex-row flex-grow bk-select" v-if="microphoneOptions.length > 0">
        <div
          :class="isMicrophoneOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'"
          @click="toggleMicrophone"
        >
          <microphone-icon
            v-if="selectedAudio && selectedAudio.value !== 'microphone-off'"
            class="audio-video-settings-communication-icon"
            :width="16"
            :height="16"
          ></microphone-icon>
          <microphone-off-icon
            v-if="!selectedAudio || selectedAudio.value === 'microphone-off'"
            class="audio-video-settings-communication-icon"
            :width="16"
            :height="16"
            color="#3B86F7"
          ></microphone-off-icon>
        </div>
        <div class="w-full">
          <connect-select
            :options="microphoneOptions"
            :default="selectedAudio"
            @input-selected="onAudioInputSelected"
            @input-click="onMicrophoneClicked"
            @input-blur="onMicrophoneSelectBlur"
          ></connect-select>
        </div>
      </div>

      <div class="mt-4">
        <span>{{ $t('vue.camera') }}</span>
      </div>

      <div class="mt-2">
        <div class="flex flex-row flex-grow bk-select">
          <div :class="isCameraOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'" @click="toggleCamera">
            <video-call-icon v-if="isCameraOn" class="audio-video-settings-communication-icon" :width="16" :height="16"></video-call-icon>
            <video-call-off-icon v-else class="audio-video-settings-communication-icon" color="#3B86F7" :width="16" :height="16"></video-call-off-icon>
          </div>
          <div class="w-full">
            <connect-select :options="cameraOptions" :default="selectedVideo" @input-selected="onVideoInputSelected" v-show="!hideCamera"></connect-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectSelect from '../../../components/ConnectSelect.vue'
import MicrophoneIcon from '../../../components/icons/MicrophoneIcon.vue'
import MicrophoneOffIcon from '../../../components/icons/MicrophoneOffIcon.vue'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
import VideoCallOffIcon from '../../../components/icons/VideoCallOffIcon.vue'

export default {
  components: {
    ConnectSelect,
    MicrophoneIcon,
    MicrophoneOffIcon,
    VideoCallIcon,
    VideoCallOffIcon
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      defaultVideoBackground: require('@/assets/images/embed/video-background.png'),
      volume: 30,
      microphoneOptions: [],
      cameraOptions: [],
      blurBackground: false,
      options: [],
      hideCamera: false,
      videoStream: null,
      loaded: false
    }
  },
  computed: {
    isMicrophoneOn() {
      return this.selectedAudio && this.selectedAudio.value !== 'microphone-off'
    },
    isCameraOn() {
      return this.selectedVideo && this.selectedVideo.value !== 'camera-off'
    }
  },
  mounted() {
    navigator.mediaDevices
      .getUserMedia({ audio: { echoCancellation: true }, video: true })
      .then(this.enumerateDevices)
      .then(this.gotDevices)
      .then(this.onSelectDefaultVideo)
      .catch(this.errorCallback)
  },
  watch: {
    volume() {
      const volumeLevel = this.volume / 100

      this.$emit('video-cam-volume-props', volumeLevel)
      this.$serverBus.$emit('on-volume-changed', { level: volumeLevel })
    }
  },
  methods: {
    toggleMicrophone() {
      if (this.isMicrophoneOn) {
        const mic = this.microphoneOptions.find((x) => x.value === 'microphone-off')
        this.onAudioInputSelected(mic)
      } else {
        const mic = this.microphoneOptions.find((x) => x.value !== 'microphone-off')
        if (mic) {
          this.onAudioInputSelected(mic)
        }
      }
    },
    toggleCamera() {
      if (this.isCameraOn) {
        const camera = this.cameraOptions.find((x) => x.value === 'camera-off')
        this.onVideoInputSelected(camera)
      } else {
        const camera = this.cameraOptions.find((x) => x.value !== 'camera-off')
        if (camera) {
          this.onVideoInputSelected(camera)
        }
      }
    },
    onSelectDefaultVideo() {
      this.loaded = true
      const selectedVideo = this.selectedVideo || this.cameraOptions[0]
      if (selectedVideo) {
        this.onVideoInputSelected(selectedVideo)
      }
    },
    stopAudioTracks() {
      if (this.videoStream) {
        this.videoStream.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopVideoTracks() {
      if (this.videoStream) {
        this.videoStream.getVideoTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopAllTracks() {
      if (this.videoStream) {
        if (typeof this.videoStream.getTracks === 'undefined') {
          this.videoStream.stop()
        } else {
          this.videoStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          this.videoStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.videoStream = null
      }
      this.$emit('video-stream-props', null)
    },
    enumerateDevices(stream) {
      this.videoStream = stream
      return navigator.mediaDevices.enumerateDevices()
    },
    onMicrophoneClicked() {
      this.hideCamera = !this.hideCamera
    },
    onMicrophoneSelectBlur() {
      this.hideCamera = false
    },
    onAudioInputSelected(option) {
      this.hideCamera = false
      if (option && option.value && option.value !== 'microphone-off') {
        this.stopAudioTracks()
        this.$serverBus.$emit('audio-input-selected', option)
        const constraints = {}
        constraints.audio = { deviceId: option.value, echoCancellation: true }
        if (this.isCameraOn) {
          constraints.video = { deviceId: this.selectedVideo.value, width: 300, height: 187 }
        } else {
          constraints.video = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            this.$emit('video-stream-props', stream)
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
      } else {
        this.stopAudioTracks()
        this.$serverBus.$emit('audio-input-selected', option)
      }
    },
    onVideoInputSelected(option) {
      if (option && option.value && option.value !== 'camera-off') {
        this.stopVideoTracks()
        const constraints = {}
        constraints.video = { deviceId: option.value, echoCancellation: true }
        if (this.isMicrophoneOn) {
          constraints.audio = { deviceId: this.selectedAudio.value, width: 300, height: 187 }
        } else {
          constraints.audio = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            this.$emit('video-stream-props', stream)
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
        this.$serverBus.$emit('video-input-selected', option)
      } else {
        this.stopVideoTracks()
        this.$serverBus.$emit('video-input-selected', option)
      }
    },
    gotDevices(deviceInfos) {
      this.microphoneOptions = []
      this.cameraOptions = []
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i]
        const option = {}
        option.value = deviceInfo.deviceId
        if (deviceInfo.kind === 'audioinput') {
          option.text = deviceInfo.label || `Microphone ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'audiooutput') {
          option.text = deviceInfo.label || `Speaker ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `Camera ${this.options.length + 1}`
          this.cameraOptions.push(option)
        }
      }
      this.microphoneOptions.unshift({ text: 'Microphone off', value: 'microphone-off' })
      this.cameraOptions.unshift({ text: 'Camera off', value: 'camera-off' })
    },
    errorCallback(e) {
      /* eslint-disable no-console */
      console.log(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.audio-video-settings {
  &-no-camera {
    width: 300px;
    height: 187px;
    &-container {
      width: 186px;
      height: 186px;
      &-message {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #12598d;
      }
    }
  }
  .video {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
  }
  span {
    font-size: 15px;
    line-height: 19px;
    color: #12598d;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .bk-select {
    background-color: #e6ecf1 !important;
    border-radius: 6px;
  }
  .bk-transparent {
    background-color: transparent !important;
  }
  .fw-600 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #b2b2b2;
  }
  .align-items-center {
    align-items: center !important;
  }
  .ht-36 {
    height: 36px !important;
  }
  &-icon {
    margin-left: -1px;
  }
  &-button {
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  &-communication-button {
    width: 48px;
    height: 30px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
  }
  .on {
    background: #3b86f7;
  }
  .off {
    background: #ffffff;
  }
  &-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: rgba(var(--vs-secondary), 1);
    text-transform: uppercase;
  }
  &-av-settings {
    height: 223px;
    width: 300px;
    background: #e6ecf1;
    border-radius: 6px;
    video {
      object-fit: cover;
    }
  }
  &-av-settings-background {
    height: 187px;
    width: 300px;
  }
  &-av-settings-footer {
    padding: 2px;
  }
}
</style>
