var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "connect-modal",
    {
      staticClass: "audio-video-settings",
      attrs: { show: _vm.show, width: 780, height: 384, bkcolor: "#F7F7F7" },
    },
    [
      _c("div", { staticClass: "flex flex-row space-between" }, [
        _c(
          "div",
          [
            _c("settings-icon", {
              staticClass: "audio-video-settings-icon pa-icon-default",
              attrs: { width: 20, height: 20 },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "audio-video-settings-button",
            staticStyle: { "margin-left": "auto" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c("close-circle-icon", {
              staticClass: "audio-video-settings-icon pa-bk-secondary",
              attrs: { "show-circle": false, width: 14, height: 14 },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "audio-video-settings-heading" }, [
        _vm._v(_vm._s(_vm.$t("vue.videoAudioSettings"))),
      ]),
      _c("div", { staticClass: "flex flex-row" }, [
        _c(
          "div",
          { staticClass: "flex flex-col audio-video-settings-av-settings" },
          [
            _c("div", [
              _c("video", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCameraOn,
                    expression: "isCameraOn",
                  },
                ],
                ref: "videoCam",
                staticClass: "video",
                attrs: {
                  id: "video-cam",
                  width: "300",
                  height: "187",
                  autoplay: "",
                },
              }),
              !_vm.isCameraOn
                ? _c(
                    "div",
                    {
                      staticClass:
                        "audio-video-settings-no-camera flex justify-center items-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "audio-video-settings-no-camera-container flex justify-center items-center relative",
                        },
                        [
                          _c("settings-video-cam-off", {
                            staticStyle: { position: "absolute" },
                            attrs: {
                              color: "#ffffff",
                              width: 186,
                              height: 186,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-row align-items-center audio-video-settings-av-settings-footer",
              },
              [
                _c("magic-icon", {
                  staticClass: "ml-2",
                  attrs: { color: "#b2b2b2", width: 16, height: 16 },
                }),
                _c("span", { staticClass: "ml-2 fw-600" }, [
                  _vm._v(_vm._s(_vm.$t("vue.changeVirtualBackground"))),
                ]),
                _c("vs-switch", {
                  staticClass: "mr-2",
                  staticStyle: { "margin-left": "auto" },
                  attrs: { color: "#275D73" },
                  model: {
                    value: _vm.blurBackground,
                    callback: function ($$v) {
                      _vm.blurBackground = $$v
                    },
                    expression: "blurBackground",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "ml-4 flex flex-col justify-center" }, [
          _c("div", [
            _c("div", { staticClass: "mt-2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("vue.microphoneAndLoudspeaker"))),
              ]),
            ]),
            _vm.microphoneOptions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mt-2 flex flex-row flex-grow bk-select" },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.isMicrophoneOn
                          ? "audio-video-settings-communication-button on"
                          : "audio-video-settings-communication-button off",
                        on: { click: _vm.toggleMicrophone },
                      },
                      [
                        _vm.selectedAudio &&
                        _vm.selectedAudio.value !== "microphone-off"
                          ? _c("microphone-icon", {
                              staticClass:
                                "audio-video-settings-communication-icon",
                              attrs: { width: 16, height: 16 },
                            })
                          : _vm._e(),
                        !_vm.selectedAudio ||
                        _vm.selectedAudio.value === "microphone-off"
                          ? _c("microphone-off-icon", {
                              staticClass:
                                "audio-video-settings-communication-icon",
                              attrs: {
                                width: 16,
                                height: 16,
                                color: "#3B86F7",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("connect-select", {
                          attrs: {
                            options: _vm.microphoneOptions,
                            default: _vm.selectedAudio,
                          },
                          on: {
                            "input-selected": _vm.onAudioInputSelected,
                            "input-click": _vm.onMicrophoneClicked,
                            "input-blur": _vm.onMicrophoneSelectBlur,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "mt-4" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("vue.camera")))]),
            ]),
            _c("div", { staticClass: "mt-2" }, [
              _c("div", { staticClass: "flex flex-row flex-grow bk-select" }, [
                _c(
                  "div",
                  {
                    class: _vm.isCameraOn
                      ? "audio-video-settings-communication-button on"
                      : "audio-video-settings-communication-button off",
                    on: { click: _vm.toggleCamera },
                  },
                  [
                    _vm.isCameraOn
                      ? _c("video-call-icon", {
                          staticClass:
                            "audio-video-settings-communication-icon",
                          attrs: { width: 16, height: 16 },
                        })
                      : _c("video-call-off-icon", {
                          staticClass:
                            "audio-video-settings-communication-icon",
                          attrs: { color: "#3B86F7", width: 16, height: 16 },
                        }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("connect-select", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.hideCamera,
                          expression: "!hideCamera",
                        },
                      ],
                      attrs: {
                        options: _vm.cameraOptions,
                        default: _vm.selectedVideo,
                      },
                      on: { "input-selected": _vm.onVideoInputSelected },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }