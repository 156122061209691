<template>
  <div class="av-call flex flex-col">
    <div class="av-call__inner">
      <div class="av-call-video flex flex-col" :style="SCREEN_SIZE">
        <video-screen-maximized-navbar
          :visitor-id="visitorId"
          :visitor="visitor"
          :message-mode="messageMode"
          :message-modes="messageModes"
          :audio-video-started="audioVideoStarted"
          :selected-audio="selectedAudio"
          :selected-video="selectedVideo"
          :company-name="companyName"
          :display-name="displayName"
          @minimize-screen="onMinimizeScreen"
          @close-communication-control="onCloseCommunicationControl"
          @change-communication-control="onClickCommunicationControl"
        ></video-screen-maximized-navbar>

        <video-audio-chat
          :screen="screen"
          :message-modes="messageModes"
          :visitor="visitor"
          :visitor-id="visitorId"
          :local-stream="localStream"
          :remote-stream="remoteStream"
          :volumeLevel="volumeLevel"
        ></video-audio-chat>
      </div>
    </div>
    <!-- <toolbar v-if="screen === SCREEN.MAXIMIZED"></toolbar> -->
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import { mapGetters } from 'vuex'
import VideoScreenMaximizedNavbar from './VideoScreenMaximizedNavbar.vue'

// import Toolbar from './Toolbar.vue'
import VideoAudioChat from './VideoAudioChat.vue'

const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}

export default {
  components: {
    VideoScreenMaximizedNavbar,
    // Toolbar,
    VideoAudioChat
  },
  emits: ['close-communication-control'],
  data() {
    return {
      screen: SCREEN.MAXIMIZED,
      typedMessage: '',
      SCREEN
    }
  },
  props: {
    visitorId: {
      type: String,
      required: true
    },
    visitor: {
      type: Object,
      required: true
    },
    messageMode: {
      type: String,
      required: true
    },
    messageModes: {
      type: Array,
      required: true
    },
    audioVideoStarted: {
      type: Boolean,
      required: false
    },
    localStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    remoteStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: false,
      default: 0.3
    },
    companyName: {
      type: String,
      required: false
    },
    displayName: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    SCREEN_SIZE() {
      if (this.screen === this.SCREEN.MAXIMIZED) {
        return {
          width: '100%',
          height: '100%',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      } else {
        return {
          width: '280px',
          height: '280px'
        }
      }
    }
  },
  watch: {
    screen() {
      if (this.screen === this.SCREEN.MAXIMIZED) {
        document.getElementById('draggable-modal').className = 'draggable-modal-maximized'
        document.getElementById('draggable-modal').removeAttribute('style')
      }
      if (this.screen === this.SCREEN.MINIMIZED) {
        document.getElementById('draggable-modal').className = 'draggable-modal-minimized'
        document.getElementById('draggable-modal').removeAttribute('style')
      }
    },
    messageMode() {
      if (this.messageMode === 'audio' && !this.messageModes.includes('video')) {
        document.getElementById('draggable-modal').className = 'draggable-modal-minimized'
        document.getElementById('draggable-modal').removeAttribute('style')
        this.screen = this.SCREEN.MINIMIZED
      }
    }
  },
  methods: {
    onMaximizeScreen() {
      this.screen = this.SCREEN.MAXIMIZED
    },
    onMinimizeScreen() {
      this.screen = this.SCREEN.MINIMIZED
    },
    onClickCommunicationControl(mode) {
      // let checkComplete = false
      let messageModes = [...this.messageModes]
      if (mode === 'chat' && !messageModes.includes(mode)) {
        this.$serverBus.$emit('chat-scroll-to-bottom', true)
      }

      if (!messageModes.includes(mode)) {
        messageModes.push(mode)
      } else {
        messageModes = messageModes.filter((x) => x !== mode)
      }

      this.$parent.$parent.$emit('change-communication-control', mode, messageModes, true)
    },
    onCloseCommunicationControl() {
      // this.screen = this.SCREEN.MINIMIZED
      this.$parent.$parent.$emit('close-communication-control', this.messageMode)
    }
  }
}
</script>
<style lang="scss">
.draggable-modal-minimized {
  width: 280px !important;
  height: 280px !important;
  position: absolute !important;
  top: 100px;
  right: 100px;
  z-index: 42000 !important;
}
.draggable-modal-maximized {
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  background: white !important;
  position: absolute;
  z-index: 42000 !important;
  cursor: default !important;
}
</style>
<style lang="scss" scoped>
.av-call {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__inner {
    flex: 1;
  }

  .red-bk {
    background: #f05541;
  }

  &-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
    &-button {
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pa-icon-default {
        color: rgba(var(--vs-primary), 1) !important;
        path {
          stroke: rgba(var(--vs-primary), 1) !important;
        }
      }
    }
    &-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }
  }
  &-audio {
    position: relative;
    width: 280px;
    height: 280px;
    &-header {
      background: #275D73;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 10px;
    }
  }
}
</style>
