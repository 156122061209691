var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col audio-video-settings audio-video-settings-av-settings",
    },
    [
      _c(
        "div",
        {
          staticClass: "audio-video-settings-av-settings-inner",
          class: {
            "full-size": !_vm.isBlurOption,
          },
        },
        [
          _c("video", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCameraOn,
                expression: "isCameraOn",
              },
            ],
            ref: "videoCam",
            staticClass: "video",
            attrs: { id: "video-cam", autoplay: "" },
          }),
          !_vm.isCameraOn
            ? _c(
                "div",
                {
                  staticClass:
                    "audio-video-settings-no-camera flex justify-center items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "audio-video-settings-no-camera-container flex justify-center items-center relative",
                    },
                    [
                      _c("settings-video-cam-off", {
                        staticStyle: { position: "absolute" },
                        attrs: { color: "#ffffff", width: 300, height: 300 },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm.isBlurOption
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-row align-items-center py-2 audio-video-settings-av-settings-footer",
            },
            [
              _c("magic-icon", {
                staticClass: "ml-2",
                attrs: { color: "#b2b2b2", width: 16, height: 16 },
              }),
              _c("span", { staticClass: "ml-2 fw-600" }, [
                _vm._v("Blur your background"),
              ]),
              _c("vs-switch", {
                staticClass: "mr-2",
                staticStyle: { "margin-left": "auto" },
                attrs: { color: "#275D73" },
                model: {
                  value: _vm.blurBackground,
                  callback: function ($$v) {
                    _vm.blurBackground = $$v
                  },
                  expression: "blurBackground",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }