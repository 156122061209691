var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col justify-center audio-video-settings" },
    [
      _c("div", [
        _c("div", { staticClass: "mt-2" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("vue.microphoneAndLoudspeaker")))]),
        ]),
        _vm.microphoneOptions.length > 0
          ? _c(
              "div",
              { staticClass: "mt-2 flex flex-row flex-grow bk-select" },
              [
                _c(
                  "div",
                  {
                    class: _vm.isMicrophoneOn
                      ? "audio-video-settings-communication-button on"
                      : "audio-video-settings-communication-button off",
                    on: { click: _vm.toggleMicrophone },
                  },
                  [
                    _vm.selectedAudio &&
                    _vm.selectedAudio.value !== "microphone-off"
                      ? _c("microphone-icon", {
                          staticClass:
                            "audio-video-settings-communication-icon",
                          attrs: { width: 16, height: 16 },
                        })
                      : _vm._e(),
                    !_vm.selectedAudio ||
                    _vm.selectedAudio.value === "microphone-off"
                      ? _c("microphone-off-icon", {
                          staticClass:
                            "audio-video-settings-communication-icon",
                          attrs: { width: 16, height: 16, color: "#3B86F7" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("connect-select", {
                      attrs: {
                        options: _vm.microphoneOptions,
                        default: _vm.selectedAudio,
                      },
                      on: {
                        "input-selected": _vm.onAudioInputSelected,
                        "input-click": _vm.onMicrophoneClicked,
                        "input-blur": _vm.onMicrophoneSelectBlur,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "mt-4" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("vue.camera")))]),
        ]),
        _c("div", { staticClass: "mt-2" }, [
          _c("div", { staticClass: "flex flex-row flex-grow bk-select" }, [
            _c(
              "div",
              {
                class: _vm.isCameraOn
                  ? "audio-video-settings-communication-button on"
                  : "audio-video-settings-communication-button off",
                on: { click: _vm.toggleCamera },
              },
              [
                _vm.isCameraOn
                  ? _c("video-call-icon", {
                      staticClass: "audio-video-settings-communication-icon",
                      attrs: { width: 16, height: 16 },
                    })
                  : _c("video-call-off-icon", {
                      staticClass: "audio-video-settings-communication-icon",
                      attrs: { color: "#3B86F7", width: 16, height: 16 },
                    }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("connect-select", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hideCamera,
                      expression: "!hideCamera",
                    },
                  ],
                  attrs: {
                    options: _vm.cameraOptions,
                    default: _vm.selectedVideo,
                  },
                  on: { "input-selected": _vm.onVideoInputSelected },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }