var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "send-chat-message" }, [
    _c(
      "div",
      {
        class: _vm.isModal
          ? "send-chat-message-container flex padding-2"
          : "send-chat-message-container flex p-4",
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.typedMessage,
              expression: "typedMessage",
            },
          ],
          staticClass: "send-chat-message-container-input",
          class: _vm.isModal ? "focus-input" : "",
          attrs: { type: "text", placeholder: _vm.$t("vue.writeMessage") },
          domProps: { value: _vm.typedMessage },
          on: {
            keydown: function ($event) {
              return _vm.onAgentIsTyping()
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.sendChatMessage()
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.typedMessage = $event.target.value
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "send-chat-message-container-button mr-2",
            on: {
              click: function ($event) {
                return _vm.sendChatMessage()
              },
            },
          },
          [
            _c("send-icon", {
              staticClass: "send-chat-message-container-icon",
              class:
                _vm.typedMessage.length > 0
                  ? "pa-icon-default"
                  : "pa-icon-disabled",
              attrs: { width: 25, height: 25 },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }