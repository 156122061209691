var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chatData
    ? _c(
        "div",
        { staticClass: "pa-chat-log mt-8" },
        [
          _c(
            "scroll-view",
            {
              ref: "svChatLog",
              class: !_vm.maxHeight ? "scroll-area" : "",
              style: _vm.maxHeight ? "max-height: " + _vm.maxHeight + "px" : "",
            },
            _vm._l(_vm.chatData, function (msg, index) {
              return _c(
                "div",
                { key: index, staticClass: "ml-8 mr-8" },
                [
                  _vm.chatData[index - 1]
                    ? [
                        !_vm.isSameDay(msg.time, _vm.chatData[index - 1].time)
                          ? _c("vs-divider", { staticClass: "msg-time" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.toDate(msg.time))),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.hasSentPreviousMsg(
                          _vm.chatData[index - 1].sender !== "visitor",
                          msg.sender !== "visitor"
                        )
                          ? _c("div", { staticClass: "spacer mt-8" })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "flex items-start",
                      class: [{ "flex-row-reverse": msg.sender !== "visitor" }],
                    },
                    [
                      _vm.chatData[index - 1]
                        ? [
                            !(
                              !_vm.hasSentPreviousMsg(
                                _vm.chatData[index - 1].sender === "agent",
                                msg.sender === "agent"
                              ) ||
                              !_vm.isSameDay(
                                msg.time,
                                _vm.chatData[index - 1].time
                              )
                            )
                              ? _c("div")
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "chat-message",
                          class: _vm.isInsideModal ? "w-full" : "",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-message",
                              class: {
                                "agent-message mr-5": msg.sender === "agent",
                                "visitor-message": msg.sender === "visitor",
                              },
                            },
                            [
                              _c("div", { staticClass: "top-right" }, [
                                _vm._v(_vm._s(_vm.chatTimeFromNow(msg.time))),
                              ]),
                              _c("div", { staticClass: "flex flex-col" }, [
                                msg.sender === "agent"
                                  ? _c("div", { staticClass: "agent-name" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(msg.agentName) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _c(
                                      "pre",
                                      { staticClass: "message-content" },
                                      [_vm._v(_vm._s(msg.message))]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visitorIsTyping,
                  expression: "visitorIsTyping",
                },
              ],
              staticClass: "chat-message-typing-indicator",
            },
            [
              _c("span", {
                staticStyle: { "background-color": "rgba(18, 89, 141, 0.6)" },
              }),
              _c("span", {
                staticStyle: { "background-color": "rgba(18, 89, 141, 0.8)" },
              }),
              _c("span", {
                staticStyle: { "background-color": "rgba(18, 89, 141, 1)" },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }