<template>
  <div class="pa-chat-log mt-8" v-if="chatData">
    <scroll-view ref="svChatLog" :class="!maxHeight ? 'scroll-area' : ''" :style="maxHeight ? `max-height: ${maxHeight}px` : ''">
      <div class="ml-8 mr-8" v-for="(msg, index) in chatData" :key="index">
        <!-- If previous msg is older than current time -->
        <template v-if="chatData[index - 1]">
          <vs-divider v-if="!isSameDay(msg.time, chatData[index - 1].time)" class="msg-time">
            <span>{{ toDate(msg.time) }}</span>
          </vs-divider>
          <div class="spacer mt-8" v-if="!hasSentPreviousMsg(chatData[index - 1].sender !== 'visitor', msg.sender !== 'visitor')"></div>
        </template>

        <div class="flex items-start" :class="[{ 'flex-row-reverse': msg.sender !== 'visitor' }]">
          <template v-if="chatData[index - 1]">
            <div
              v-if="!(!hasSentPreviousMsg(chatData[index - 1].sender === 'agent', msg.sender === 'agent') || !isSameDay(msg.time, chatData[index - 1].time))"
            ></div>
          </template>

          <div class="chat-message" :class="isInsideModal ? 'w-full' : ''">
            <div
              class="break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-message"
              :class="{
                'agent-message mr-5': msg.sender === 'agent',
                'visitor-message': msg.sender === 'visitor'
              }"
            >
              <div class="top-right">{{ chatTimeFromNow(msg.time) }}</div>

              <div class="flex flex-col">
                <div class="agent-name" v-if="msg.sender === 'agent'">
                  {{ msg.agentName }}
                </div>
                <div style="text-align: left">
                  <pre class="message-content">{{ msg.message }}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </scroll-view>
    <div class="chat-message-typing-indicator" v-show="visitorIsTyping">
      <span style="background-color: rgba(18, 89, 141, 0.6)"></span>
      <span style="background-color: rgba(18, 89, 141, 0.8)"></span>
      <span style="background-color: rgba(18, 89, 141, 1)"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScrollView from '@blackbp/vue-smooth-scrollbar'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  components: {
    ScrollView
  },
  props: {
    visitorId: {
      type: String,
      required: true
    },
    visitor: {
      type: Object,
      requried: false
    },
    maxHeight: {
      type: Number,
      required: false,
      default: null
    },
    isInsideModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      visitorIsTyping: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'users'
    }),
    chatData() {
      return this.visitor && this.visitor.chats ? this.visitor.chats : []
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL
    },
    hasSentPreviousMsg() {
      return (last_sender, current_sender) => last_sender === current_sender
    }
  },
  mounted() {
    /* To Scroll to bottom for Chat inside poppup (Audio/Video) */
    if (this.isInsideModal) {
      setTimeout(() => {
        const scrollElement = this.$refs.svChatLog
        const scrollBar = scrollElement.scrollBar
        if (scrollElement && scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height) {
          scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
        }
      }, 100)
    }

    this.$serverBus.$on('visitor-is-typing', (t) => {
      this.visitorIsTyping = t
    })
  },
  updated() {
    const scrollElement = this.$refs.svChatLog
    const scrollBar = scrollElement.scrollBar
    if (scrollElement && scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height) {
      scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
    }
  },
  methods: {
    isSameDay(time_to, time_from) {
      let to = time_to,
        from = time_from

      if (typeof time_to === 'object' && time_to.seconds) {
        to = dayjs.unix(time_to.seconds).toISOString()
      }

      if (typeof time_from === 'object' && time_from.seconds) {
        from = dayjs.unix(time_from.seconds).toISOString()
      }

      const date_time_to = new Date(Date.parse(to))
      const date_time_from = new Date(Date.parse(from))
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      )
    },
    toDate(time) {
      if (typeof time === 'string' && time.seconds) {
        return dayjs(time).locale(this.$i18n.locale).tz(this.$defaultTimezone).format('DD MMM')
      }
      if (typeof time === 'object' && time.seconds) {
        return dayjs.unix(time.seconds).locale(this.$i18n.locale).tz(this.$defaultTimezone).format('DD MMM')
      }
    },
    chatTimeFromNow(time) {
      if (typeof time === 'string') {
        return dayjs(time).tz(this.$defaultTimezone).locale(this.$i18n.locale).fromNow()
      }
      if (typeof time === 'object' && time.seconds) {
        return dayjs.unix(time.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).fromNow()
      }
      return ''
    }
  }
}
</script>
<style lang="scss">
.pa-chat-log {
  min-height: 200px;
  .scroll-area {
    width: 100%;
    height: calc(50vh);
  }
  .chat-message {
    @keyframes typing {
      50% {
        opacity: 1;
        transform: scale(1.25);
      }
    }
    @keyframes visible {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &-typing-indicator {
      display: table;
      position: absolute;
      margin-left: 50px;
      margin-top: -20px;
      animation: 1s visible;
      span {
        height: 10px;
        width: 10px;
        float: left;
        margin: 0 5px;
        display: block;
        border-radius: 50%;
        opacity: 0.4;
        @for $i from 1 through 3 {
          &:nth-of-type(#{$i}) {
            animation: 1s typing infinite ($i * 0.3333s);
          }
        }
      }
    }
    min-width: 200px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    .agent-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      color: #3b86f7;
      text-transform: uppercase;
      margin-top: 10px;
      text-align: left;
    }
    .agent-message {
      background: #e6ecf1;
      border-radius: 6px;
      color: rgba(var(--vs-secondary), 1) !important;
      display: flex;
      flex-wrap: wrap;
      .top-right {
        color: #b2b2b2;
      }
    }
    .visitor-message {
      background: #c4c4c4;
      border-radius: 6px;
      color: rgba(var(--vs-secondary), 1) !important;
      display: flex;
      flex-wrap: wrap;
      .top-right {
        color: white;
      }
    }
    .message-content {
      font-family: 'Lato';
      margin-top: 7px;
      white-space: pre-wrap;
      word-break: keep-all;
    }

    .top-right {
      position: absolute;
      top: 2px;
      right: 10px;
      color: #b2b2b2;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
    }
    .max-w-message {
      max-width: 34rem !important;
    }
  }
}
</style>
