<template>
  <div
    v-if="meeting"
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center px-3"
    :class="{
      'bg-img_gray': step === 2 && (isCall || isAgent)
    }"
  >
    <div
      class="meeting-container"
      :class="{
        'meeting-container_darken': step === 2,
        'meeting-container_calling': step > 2 || (step === 2 && isAgent)
      }"
    >
      <div v-if="step === 1" class="meeting-inner">
        <div class="video-block">
          <Video :selected-audio="selectedAudio" :selected-video="selectedVideo" :video-stream-props="videoStreamProps" :volume-level-props="volumeLevel" />
        </div>

        <div class="call-settings">
          <div class="call-settings__header">
            <div class="call-settings__header__name">{{ meeting.companyName }}</div>

            <div v-if="isAgent" class="call-settings__header__details">
              {{ meeting.message || 'The visitor did not leave a message' }}
            </div>

            <div v-else class="call-settings__header__details call-settings__header__details-visitor">
              {{ bookedSlotInfo }}
            </div>
          </div>

          <div class="call-settings__permissions">
            <Settings
              :selected-audio="selectedAudio"
              :selected-video="selectedVideo"
              @video-stream-props="videoStreamProps = $event"
              @video-cam-volume-props="volumeLevel = $event"
            />
          </div>

          <div class="call-settings__actions">
            <button v-if="isAgent" class="btn-action" @click="startMeeting">START MEETING</button>

            <button v-else class="btn-action" @click="joinMeeting">JOIN MEETING</button>

            <button class="mt-2 btn-action btn-action_transparent">CANCEL</button>
          </div>
        </div>
      </div>

      <div
        v-else-if="step === 2"
        class="meeting-inner"
        :class="{
          'meeting-inner_loading': isAgent
        }"
      >
        <template v-if="!isAgent">
          <button class="btn-close">
            <PhoneIcon class="btn-close__icon" :width="24" :height="24" />
          </button>

          <VsProgress indeterminate class="loader-progress" color="#12598D" height="8" />
        </template>

        <div class="video-block">
          <Video
            :selected-audio="selectedAudio"
            :selected-video="selectedVideo"
            :is-blur-option="false"
            :video-stream-props="videoStreamProps"
            :volume-level-props="volumeLevel"
          />
        </div>

        <!-- <div
          class="video-block"
          :class="{
            'video-block_voice-call': isCall && !isCameraOn,
          }"
        >
          <template v-if="isCall && isCameraOn">Video</template>

          <template v-else-if="isCall && !isCameraOn">
            <div class="video-block_voice-call__heading">
              <div class="video-block_voice-call__heading__avatar" />

              <div class="video-block_voice-call__heading__info">
                <div class="video-block_voice-call__heading__company">COMPANY NAME</div>
                <div class="video-block_voice-call__heading__name">TOM MUSTERMANN</div>
              </div>
            </div>

            <AudioIcon
              class="call-settings__audio-icon"
              :width="186"
              :height="73"
            />
          </template>
        </div> -->

        <div class="call-settings">
          <div v-if="isAgent" class="call-settings__agent-waiting-block">
            <template v-if="!isCusotmerReady">
              <div class="call-settings__agent-waiting-block__name">Waiting for {{ meeting.companyName }}</div>
              <div class="call-settings__agent-waiting-block__details">{{ meeting.message || 'The visitor did not leave a message' }}</div>

              <div class="lds-grid">
                <div />
                <div />
                <div />
              </div>
            </template>

            <template v-else-if="isCusotmerReady && !isCall">
              <div class="call-settings__agent-waiting-block__name">{{ meeting.companyName }} is already in the waiting area</div>

              <button class="btn-action call-settings__agent-waiting-block__btn-allow" @click="isCall = true">ALLOW</button>
            </template>

            <template v-else-if="isCall && !isCameraOn">
              <div class="call-settings__agent-waiting-block__name">{{ meeting.companyName }}</div>

              <AudioIcon class="call-settings__audio-icon" :width="186" :height="73" />
            </template>
          </div>

          <div v-else class="call-settings__waiting-title">Hi, I'll be here for you in a minute and look forward to see you!</div>
        </div>
      </div>
    </div>

    <!-- <button @click="endSession">End session</button> -->

    <AudioVideoCallModal
      v-if="isCall || (step === 2 && isAgent)"
      :visitorId="visitorId"
      :visitor="visitor"
      :message-mode="messageMode"
      :message-modes="messageModes"
      :audio-video-started="audioVideoStarted"
      :local-stream="localStream"
      :remote-stream="remoteStream"
      :selected-audio="selectedAudio"
      :selected-video="selectedVideo"
      :volume-level="volumeLevel"
      :company-name="meeting.companyName"
      :display-name="meeting.displayName"
    />

    <AudioVideoSettings :show="showSettings" :selected-audio="selectedAudio" :selected-video="selectedVideo" @close="onCloseSettings" />

    <div v-if="step === 1" class="banner">
      <img src="@/assets/images/logo/face-to-face-logo.png" alt="powered by Let’s Connect" />
    </div>
  </div>
</template>

<script>
/* eslint-disable quotes */
import { mapGetters } from 'vuex'
import moment from 'moment'

import PhoneIcon from '../../components/icons/PhoneIcon'
import AudioIcon from '../../components/icons/AudioIcon.vue'
import AudioVideoCallModal from './call-settings/AudioVideoCallModal'
import Video from './call-settings/Video'
import Settings from './call-settings/Settings'
import AudioVideoSettings from './call-settings/AudioVideoSettings.vue'

export default {
  components: {
    PhoneIcon,
    AudioIcon,
    AudioVideoCallModal,
    Video,
    Settings,
    AudioVideoSettings
  },

  data: () => ({
    videoStreamProps: null,
    visitorId: 'R3R4kzXeANYNcPeJHNE0',

    visitor: {
      dialogType: 'button-card',
      surlfyEndTime: {
        seconds: 1620938554,
        nanoseconds: 749000000
      },
      isp: '',
      audioPlayed: false,
      lastReadChatsAgentInfo: [
        {
          lastRead: 11488124402,
          agentId: 'vf6dO6x7PKOEfqodwNmKEWBEwb42'
        }
      ],
      citylatlong: '46.975033,31.994583',
      referer: 'https://localhost:8080/',
      modified: {
        seconds: 1620935701,
        nanoseconds: 454000000
      },
      agentId: 'vf6dO6x7PKOEfqodwNmKEWBEwb42',
      online: true,
      agentRequestType: 'chat',
      agentRequest: true,
      origin: 'https://localhost:8080',
      owner: 'WildPark Co',
      connectedAgentImage: '/user.png',
      start_time: {
        seconds: 1620940121,
        nanoseconds: 637000000
      },
      location: 'https://localhost:8080/ed/alex.html',
      created: {
        seconds: 1620939222,
        nanoseconds: 228000000
      },
      disconnect: {
        seconds: 1620938554,
        nanoseconds: 749000000
      },
      country: 'UA',
      userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.212 Safari/537.36',
      callType: 'incoming-request',
      dialogDate: {
        seconds: 1620939225,
        nanoseconds: 726000000
      },
      connectedAgent: 'Alex Bilyk',
      companyName: 'Test',
      companyLogoUrl:
        'https://storage.googleapis.com/download/storage/v1/b/pathadvice-stage.appspot.com/o/company-logos%2FjDI59cvNI23FvVLToX3c.ico?generation=1615307046602068&alt=media',
      netname: 'WPARK',
      b2b: false,
      dialog: true,
      totalVisits: 3,
      cobrowse: false,
      region: '48',
      callTypeState: 'agent-joined',
      connectedAgentDate: {
        seconds: 1620940124,
        nanoseconds: 403000000
      },
      companyInfoId: '1oqPCd3xIxoMvHhgHcWU',
      locale: 'en',
      type: 'embed',
      company: 'uYVvFVrsxE5B2SUphEj3',
      end_time: {
        seconds: 1620939403,
        nanoseconds: 156000000
      },
      agentsToBeNotified: ['vf6dO6x7PKOEfqodwNmKEWBEwb42'],
      dialogId: 'WURhESlV8J0mMcoYfPXl',
      abTestId: 'Hhlk3ueQ89YJoT9vSIrQ',
      city: 'Mykolaiv, 48',
      score: 5,
      connectedAgentId: 'vf6dO6x7PKOEfqodwNmKEWBEwb42',
      firstSeen: {
        seconds: 1620935694,
        nanoseconds: 978000000
      },
      id: 'R3R4kzXeANYNcPeJHNE0',
      name: 'WildPark Co',
      scoring: 50,
      lastSeen: '26 minutes ago',
      lastSeenTimestamp: '',
      icons: {
        browser: {
          name: 'chrome',
          style: 'fab',
          html: "<i class='fab fa-chrome'/>"
        },
        os: {
          name: 'windows',
          style: 'fab',
          html: "<i class='fab fa-windows'/>"
        },
        platform: {
          name: 'desktop',
          style: 'fas',
          html: "<i class='fas fa-desktop'/>"
        }
      },
      sortOrder: 1
    },

    visitorData:
      '{"netname":"WPARK","abTestId":"Hhlk3ueQ89YJoT9vSIrQ","country":"UA","score":5,"created":{"seconds":1620891633,"nanoseconds":748000000},"companyLogoUrl":"https://storage.googleapis.com/download/storage/v1/b/pathadvice-stage.appspot.com/o/company-logos%2FjDI59cvNI23FvVLToX3c.ico?generation=1615307046602068&alt=media","referer":"https://localhost:8080/","agentsToBeNotified":["vf6dO6x7PKOEfqodwNmKEWBEwb42"],"userAgent":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.93 Safari/537.36","company":"uYVvFVrsxE5B2SUphEj3","dialogType":"button-card","locale":"en","dialog":true,"modified":{"seconds":1620888665,"nanoseconds":622000000},"start_time":{"seconds":1620891640,"nanoseconds":198000000},"companyInfoId":"1oqPCd3xIxoMvHhgHcWU","agentRequestType":"chat","type":"embed","disconnect":{"seconds":1620892864,"nanoseconds":898000000},"b2b":false,"agentRequest":false,"owner":"WildPark Co","audioPlayed":false,"location":"https://localhost:8080/ed/alex.html","callTypeState":"incoming","online":false,"citylatlong":"46.975033,31.994583","dialogId":"WURhESlV8J0mMcoYfPXl","dialogDate":{"seconds":1620891637,"nanoseconds":97000000},"callType":"incoming-request","totalVisits":3,"companyName":"Test","isp":"","cobrowse":false,"city":"mykolaiv","origin":"https://localhost:8080","region":"48","firstSeen":{"seconds":1620888659,"nanoseconds":632000000},"surlfyEndTime":{"seconds":1620892864,"nanoseconds":898000000}}',

    meeting: null,
    meetingId: null,

    unsubscribeVisitor: null,
    viewType: null,
    skipUpdate: false,
    isWaiting: false,
    communicationMode: null,
    agentInfo: {
      companyName: '',
      connectedAgentImage: '',
      connectedAgent: ''
    },

    messageMode: 'audio',
    messageModes: ['audio'],
    audioVideoStarted: true,
    localStream: null,
    remoteStream: null,
    selectedAudio: null,
    selectedVideo: null,
    volumeLevel: 0.3,

    step: 1,
    isCusotmerReady: false,
    isCall: false,
    isCameraOn: false,
    showSettings: false
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),

    isAgent() {
      return this.activeUserInfo && this.meeting && this.activeUserInfo.uid === this.meeting.agentId
    },

    bookedSlotInfo() {
      if (!(this.meeting && this.meeting.date)) {
        return ''
      }

      const date = moment(new Date(this.meeting.date))
      const formatedDate = date.format('DD.MM.YYYY')
      const formatedTime = date.format('HH:mm')

      return `BOOKED SLOT: ${formatedDate} | ${formatedTime}`
    },

    agentConnected() {
      return !!(this.agentInfo && this.agentInfo.connectedAgent)
    }
  },

  async created() {
    await this.getMeeting()
    this.$serverBus.$on('audio-input-selected', (option) => {
      this.selectedAudio = option
    })

    this.$serverBus.$on('video-input-selected', (option) => {
      this.selectedVideo = option
    })
  },

  methods: {
    async getMeeting() {
      this.meetingId = this.$route.params.id

      const meetingRef = await this.$db.collection('meetings').doc(this.meetingId).get()

      this.meeting = meetingRef.data()
    },

    async startMeeting() {
      this.step = 2
      // this.isCall = true

      // this.requestAgent()

      setTimeout(() => {
        this.isCusotmerReady = true
      }, 5000)
    },

    joinMeeting() {
      this.step = 2
      this.isCall = false
    },

    onCloseSettings() {
      this.showSettings = false
    },

    onMinimizeScreen() {
      this.screen = this.SCREEN.MINIMIZED
    },

    onClickCommunicationControl(mode) {
      let messageModes = [...this.messageModes]

      if (mode === 'chat' && !messageModes.includes(mode)) {
        this.$serverBus.$emit('chat-scroll-to-bottom', true)
      }

      if (!messageModes.includes(mode)) {
        messageModes.push(mode)
      } else {
        messageModes = messageModes.filter((x) => x !== mode)
      }

      this.$parent.$parent.$emit('change-communication-control', mode, messageModes, true)
    },

    onVisitorEntityChange() {
      const visitorEntity = this.$db.collection('visitors').doc(this.meeting.visitorId)

      this.unsubscribeVisitor = visitorEntity.onSnapshot((doc) => {
        if (this.skipUpdate) {
          return
        }

        const visitor = doc.data()

        this.isWaiting = !(visitor.connectedAgent && visitor.connectedAgentId)
        this.viewType = 'meeting-room'

        this.agentInfo.connectedAgent = visitor.connectedAgent
        this.agentInfo.companyName = visitor.companyName
        this.agentInfo.connectedAgentImage = visitor.connectedAgentImage

        if (visitor.callTypeState === 'meeting-room-complete') {
          this.viewType = 'finish-communication'
          this.isWaiting = false
        }
      })
    },

    requestAgent() {
      if (this.agentConnected) {
        return
      }

      this.communicationMode = 'video'

      this.onVisitorEntityChange()

      const data = {
        meetingRequest: true,
        agentRequestType: this.communicationMode,
        callType: 'incoming-request',
        callTypeState: 'incoming',
        audioPlayed: false,
        start_time: new Date()
      }

      this.$db.collection('visitors').doc(this.meeting.visitorId).set(data, { merge: true })
    },

    endSession() {
      const data = {
        end_time: new Date(),
        connectedAgentId: this.$firebase.firestore.FieldValue.delete(),
        connectedAgent: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentImage: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentDate: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentChatDate: this.$firebase.firestore.FieldValue.delete(),
        //agentsToBeNotified: this.$firebase.firestore.FieldValue.delete(), DELETE AGENTS TO BE NOTIFIED
        callTypeState: 'meeting-complete',
        meetingRequest: false,
        audioPlayed: false
      }

      this.$db.collection('visitors').doc(this.meeting.visitorId).set(data, { merge: true })

      this.onRemoveStreams()

      this.$database.ref(`/webrtc/${this.meeting.visitorId}/visitor`).set({ closed: true })

      if (this.unsubscribeVisitor) {
        this.unsubscribeVisitor()
      }
    },

    onRemoveStreams() {
      this.onRemoveLocalStream()
      this.onRemoveRemoteStream()
    },

    onRemoveLocalStream() {
      if (this.localStream) {
        if (typeof this.localStream.getTracks === 'undefined') {
          this.localStream.stop()
        } else {
          this.localStream.getTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.localStream = null
      }
    },

    onRemoveRemoteStream() {
      if (this.remoteStream) {
        if (typeof this.remoteStream.getTracks === 'undefined') {
          this.remoteStream.stop()
        } else {
          this.remoteStream.getTracks().forEach(function (track) {
            track.stop()
          })
        }

        this.remoteStream = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: 'Lato', sans-serif;
}

.banner {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.pa-bk-secondary {
  color: rgb(255, 255, 255) !important;
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
}

.pa-icon-default {
  color: rgba(var(--vs-primary), 1) !important;

  path {
    stroke: rgba(var(--vs-primary), 1) !important;
  }
}

.btn {
  &-action {
    cursor: pointer;
    width: 100%;
    background: #1c8439;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 6px 0;

    &_transparent {
      color: rgb(18, 89, 141);
      background: transparent;
      box-shadow: none;
    }
  }

  &-close {
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 15px;
    width: 35px;
    height: 35px;
    font-weight: 700;
    background: rgb(240, 85, 65);
    border-radius: 6px;
    border: none;
    cursor: pointer;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));

    &:hover {
      background: darken(rgb(240, 85, 65), 10%);
      transition: all 0.2s linear;
    }

    &__icon {
      margin: 0 auto;
      width: 18px;
      height: 18px;
    }
  }
}

.loader-progress {
  position: absolute;
  bottom: 20px;
  left: 15px;
  width: calc(100% - 30px);
}

.lds-grid {
  display: inline-block;
  position: absolute;
  width: 174px;
  height: 62px;
  top: 64%;
  left: 50%;
  transform: translateX(-50%);

  div {
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgb(18, 89, 141);
    animation: lds-grid 1.2s linear infinite;

    &:nth-child(1) {
      top: 17px;
      left: 17px;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      top: 17px;
      left: 70px;
      animation-delay: -0.4s;
    }

    &:nth-child(3) {
      top: 17px;
      left: 123px;
      animation-delay: -0.8s;
    }
  }
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.h-screen.bg-img {
  background: rgb(18, 89, 141);
}

.h-screen.bg-img_gray {
  background: rgb(230, 236, 241);
}

.meeting {
  &-container {
    position: relative;
    width: 1034px;
    height: 505px;
    max-height: 60%;
    min-height: 400px;
    background: rgb(247, 247, 247);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    z-index: 99;

    &_darken {
      background: rgb(230, 236, 241);
    }

    &_calling {
      width: 1350px;
      max-width: 95%;
      min-width: 800px;
      height: 557px;
      background: none;
      border-radius: 0;
      box-shadow: none;

      .meeting {
        &-inner {
          padding: 0;
          justify-content: center;
        }
      }

      .video-block {
        margin-right: 7px;
        width: calc(50% - 7px);
        border-radius: 6px;
        background: rgb(255, 255, 255);

        &_voice-call {
          justify-content: flex-start;
          background-color: rgb(18, 89, 141);
          background-image: url('../../assets/images/elements/bg-wave.png') !important;
          background-repeat: no-repeat;
          background-size: auto 105%;
          background-position: -50px -5px;

          &__heading {
            display: flex;
            align-items: center;
            margin-top: -150px;
            margin-left: 50px;
            color: rgb(255, 255, 255);

            &__avatar {
              width: 128px;
              height: 128px;
              background: rgb(255, 255, 255);
              border-radius: 50%;
            }

            &__info {
              margin-left: 25px;
            }

            &__company {
              font-size: 18px;
            }

            &__name {
              margin-top: 10px;
              font-size: 30px;
              font-weight: 700;
            }
          }
        }
      }

      .call-settings {
        position: relative;
        padding: 20px;
        width: calc(50% - 7px);
        background: rgb(18, 89, 141);
        border-radius: 6px;

        &__agent-waiting-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 10%;
          padding-right: 10%;
          width: 100%;
          height: 100%;
          color: rgb(255, 255, 255);
          background-image: url('../../assets/images/elements/bg-cricle.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;

          @media screen and (min-width: 992px) {
            padding-left: 20%;
            padding-right: 20%;
          }

          &__name {
            margin-top: -50%;
            font-size: 30px;
            font-weight: 700;
            line-height: 38px;
            text-align: center;
          }

          &__details {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 500;
          }

          &__btn {
            &-allow {
              position: absolute;
              top: 60%;
              left: 50%;
              padding: 14px 70px;
              width: auto;
              font-size: 16px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  &-inner {
    display: flex;
    padding: 20px 20px 30px;
    width: 100%;
    height: 100%;

    &_loading {
      padding-bottom: 40px;
    }
  }
}

.video-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 50%;
}

.call-settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 0 45px 0 55px;
  width: 50%;

  &__header {
    &__name {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #12598d;
      text-transform: uppercase;
    }

    &__details {
      margin-top: 5px;
      height: 40px;
      color: #b2b2b2;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;

      &-visitor {
        text-transform: uppercase;
      }
    }
  }

  &__permissions {
    margin-top: 20px;
    height: 180px;
  }

  &__actions {
    margin-top: 40px;
  }

  &__waiting-title {
    color: rgb(18, 89, 141);
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3;
  }

  &__audio-icon {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
