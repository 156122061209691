<template>
  <div class="send-chat-message">
    <div :class="isModal ? 'send-chat-message-container flex padding-2' : 'send-chat-message-container flex p-4'">
      <input
        :class="isModal ? 'focus-input' : ''"
        class="send-chat-message-container-input"
        v-model="typedMessage"
        type="text"
        :placeholder="$t('vue.writeMessage')"
        @keydown="onAgentIsTyping()"
        @keyup.enter="sendChatMessage()"
      />
      <div class="send-chat-message-container-button mr-2" @click="sendChatMessage()">
        <send-icon
          class="send-chat-message-container-icon"
          :class="typedMessage.length > 0 ? 'pa-icon-default' : 'pa-icon-disabled'"
          :width="25"
          :height="25"
        ></send-icon>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
import { mapGetters } from 'vuex'
import SendIcon from '../../../components/icons/SendIcon.vue'
import sanitizeHtml from 'sanitize-html'

export default {
  components: {
    SendIcon
  },
  props: {
    visitorId: {
      type: String,
      required: true
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    /* Get reference of real-time db */
    this.rtdVisitorRef = this.$database.ref(`/chats/${this.visitorId}`)
    /* Initially set the typing to false */
    this.$serverBus.$emit('visitor-is-typing', false)
    /* Listen for updates in the real-time db for visitors typing */
    this.rtdVisitorRef.on('value', (snapshot) => {
      const data = snapshot.val()
      if (data && typeof data.visitorIsTyping !== 'undefined') {
        this.visitorIsTyping = data.visitorIsTyping
        this.$serverBus.$emit('visitor-is-typing', data.visitorIsTyping)
      }
    })
    /* Automatically reduce timeout milli-seconds to 2000 (default) if it is greater than 2000 */
    setInterval(this.reduceTimeoutMs, 1000)
  },
  data() {
    return {
      typedMessage: '',
      agentIsTyping: false,
      visitorIsTyping: false,
      timeoutMs: 2000,
      rtdVisitorRef: null
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    agentName() {
      let name = ''
      if (this.activeUserInfo.firstname) {
        name = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          name = `${name} ${this.activeUserInfo.lastname}`
        }
      } else if (this.activeUserInfo.displayName) {
        name = this.activeUserInfo.displayName
      }
      return name
    }
  },
  methods: {
    sanitizeHtml,
    async sendChatMessage() {
      const chatMessage = this.typedMessage.trim()
      this.typedMessage = ''
      if (chatMessage.length > 0) {
        const visitorRef = this.$db.collection('visitors').doc(this.visitorId)
        const message = {
          message: this.sanitizeHtml(chatMessage),
          time: new Date(),
          chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
          agentName: this.agentName,
          agentId: this.activeUserInfo.uid,
          sender: 'agent'
        }
        await visitorRef.update({
          chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
          callTypeState: 'agent-chatting',
          agentMessages: this.$firebase.firestore.FieldValue.increment(1),
          visitorMessages: this.$firebase.firestore.FieldValue.delete()
        })
      }
    },
    onAgentIsTyping() {
      if (!this.agentIsTyping) {
        this.rtdVisitorRef.update({ agentIsTyping: true })
        this.waitForRemainingSeconds()
      }
      if (this.timeoutMs === 2000) {
        this.timeoutMs += 4000
      }
    },
    reduceTimeoutMs() {
      if (this.timeoutMs > 2000) {
        this.timeoutMs -= 1000
      }
    },
    waitForRemainingSeconds() {
      setTimeout(this.onAgentStoppedTyping, this.timeoutMs)
    },
    onAgentStoppedTyping() {
      if (this.timeoutMs > 2000) {
        this.waitForRemainingSeconds()
      } else {
        this.agentIsTyping = false
        this.rtdVisitorRef.update({ agentIsTyping: false })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.send-chat-message {
  &-container {
    &-input {
      font-family: 'Lato';
      border: none;
      width: 100%;
      padding: 5px;
      font-size: 15px;
      line-height: 19px;
      color: #12598d;
    }
    &-input::placeholder {
      color: #12598d;
      opacity: 0.5;
    }
    &-button {
      width: 106px;
      height: 35px;
      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 1s;
      .pa-icon-disabled {
        color: rgba(0, 0, 0, 0.2) !important;
        path {
          stroke: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
    &-button:hover {
      background: rgba(var(--vs-secondary), 0.2) !important;
    }
  }
}
</style>
