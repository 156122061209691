<template>
  <div :class="screen === SCREEN.MAXIMIZED ? 'video-audio-chat-video-visitor-maximized' : ''">
    <div v-if="screen === SCREEN.MINIMIZED && !hasVideoStream">
      <div class="video-audio-chat-audio">
        <div class="video-audio-chat-audio-header">
          <div>
            <audio-icon :width="199" :height="79"></audio-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- Visitor Video Placeholder -->
    <div
      :class="screen === SCREEN.MINIMIZED ? 'video-audio-chat-video-visitor-video-minimized' : 'video-audio-chat-video-visitor-video-maximized mr-4'"
      :style="hideVideoStyle"
    >
      <video ref="remoteVideo" autoplay></video>
    </div>
    <!-- Agent Video Placeholder -->
    <div
      :class="screen === SCREEN.MINIMIZED ? 'video-audio-chat-video-agent-video-minimized' : 'video-audio-chat-video-agent-video-maximized ml-4 mr-4'"
      :style="hideVideoStyle"
    >
      <video ref="localVideo" id="local-video" class="video" muted autoplay></video>
    </div>
    <visitor-chats
      class="video-audio-chat-chat-messages"
      v-if="screen === SCREEN.MAXIMIZED"
      :visitor-id="visitorId"
      :visitor="visitor"
      :message-modes="messageModes"
      :screen="screen"
    ></visitor-chats>
  </div>
</template>
<script>
const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}
import { mapGetters } from 'vuex'
import VisitorChats from './VisitorChats.vue'

import AudioIcon from '../../../components/icons/AudioIcon.vue'
export default {
  props: {
    screen: {
      type: String,
      required: true
    },
    visitorId: {
      type: String,
      required: true
    },
    visitor: {
      type: Object,
      required: true
    },
    messageModes: {
      type: Array,
      required: false
    },
    localStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    remoteStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: false,
      default: 0.3
    }
  },
  components: {
    VisitorChats,
    AudioIcon
  },
  mounted() {
    if (!this.messageModes.includes('video')) {
      this.$refs.localVideo.srcObject = null
      this.$refs.remoteVideo.srcObject = null
    }

    if (this.messageModes.includes('video')) {
      if (this.localStream) {
        this.$refs.localVideo.srcObject = this.localStream
        this.localStreamCount = this.localStream.getVideoTracks().filter((x) => x.enabled).length
      }
      if (this.remoteStream) {
        this.$refs.remoteVideo.srcObject = this.remoteStream
        this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
    }
  },
  watch: {
    localStream() {
      if (this.localStream) {
        this.$refs.localVideo.srcObject = this.localStream
        this.localStreamCount = this.localStream.getVideoTracks().filter((x) => x.enabled).length
      }
    },
    remoteStream() {
      if (this.remoteStream) {
        this.$refs.remoteVideo.srcObject = this.remoteStream
        this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
      }
    },
    messageModes() {
      this.localStreamCount = this.localStream.getVideoTracks().filter((x) => x.enabled).length
      this.remoteStreamCount = this.remoteStream.getVideoTracks().filter((x) => x.enabled).length
    },
    volumeLevel() {
      if (this.$refs.localVideo) {
        this.$refs.localVideo.volume = this.volumeLevel
      }
    }
  },
  data() {
    return {
      SCREEN,
      localStreamCount: 0,
      remoteStreamCount: 0
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    hasVideoStream() {
      const hasVideoStream = this.localStreamCount > 0 || this.remoteStreamCount > 0
      return hasVideoStream
    },
    hideAudioStyle() {
      if (this.hasVideoStream) {
        return {
          visibility: 'hidden'
        }
      } else {
        return {
          visibility: 'visible'
        }
      }
    },
    hideVideoStyle() {
      if (this.hasVideoStream) {
        return {
          visibility: 'visible'
        }
      } else {
        return {
          visibility: 'hidden'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-audio-chat {
  .video {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
  }
  padding: 10px;
  &-video {
    &-visitor {
      &-maximized {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-evenly;
        padding: 0px;
      }
      &-video {
        &-minimized {
          video {
            width: 280px;
            height: 245px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            object-fit: cover;
          }
        }
        &-maximized {
          height: calc(80vh);
          display: flex;
          video {
            max-width: calc(30vw);
            //min-width: calc(30vw);
            width: 100%;
            height: 60%;
            align-self: center;
            //height: calc(60vh);
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            object-fit: cover;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
          }
          video:hover {
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
    &-agent {
      &-video {
        &-minimized {
          position: absolute;
          bottom: 65px;
          right: 0px;
          video {
            height: 65px;
            width: 86.67px;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
            position: relative;
            overflow: hidden;
            padding: 0;
            border: none;
            flex-shrink: 0;
            background-color: transparent;
            background-clip: padding-box;
            cursor: pointer;
            position: relative;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            display: flex;
            z-index: 2;
            border-radius: 5px;
            object-fit: cover;
          }
        }
        &-maximized {
          height: calc(80vh);
          display: flex;
          video {
            width: 100%;
            height: 60%;
            max-width: calc(30vw);
            //min-width: calc(30vw);
            align-self: center;

            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            object-fit: cover;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
          }
          video:hover {
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }

  &-chat-messages {
    width: 100%;
    height: auto;
    max-width: 300px;
    align-self: center;
  }

  &-audio {
    position: relative;
    width: 280px;
    height: 280px;
    &-header {
      background: #12598d;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 10px;
    }
  }
}
</style>
