var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "av-call flex flex-col" }, [
    _c("div", { staticClass: "av-call__inner" }, [
      _c(
        "div",
        { staticClass: "av-call-video flex flex-col", style: _vm.SCREEN_SIZE },
        [
          _c("video-screen-maximized-navbar", {
            attrs: {
              "visitor-id": _vm.visitorId,
              visitor: _vm.visitor,
              "message-mode": _vm.messageMode,
              "message-modes": _vm.messageModes,
              "audio-video-started": _vm.audioVideoStarted,
              "selected-audio": _vm.selectedAudio,
              "selected-video": _vm.selectedVideo,
              "company-name": _vm.companyName,
              "display-name": _vm.displayName,
            },
            on: {
              "minimize-screen": _vm.onMinimizeScreen,
              "close-communication-control": _vm.onCloseCommunicationControl,
              "change-communication-control": _vm.onClickCommunicationControl,
            },
          }),
          _c("video-audio-chat", {
            attrs: {
              screen: _vm.screen,
              "message-modes": _vm.messageModes,
              visitor: _vm.visitor,
              "visitor-id": _vm.visitorId,
              "local-stream": _vm.localStream,
              "remote-stream": _vm.remoteStream,
              volumeLevel: _vm.volumeLevel,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }