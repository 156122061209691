<template>
  <div class="agent-header">
    <vs-avatar class="ml-4" src="/user.png" size="45px"></vs-avatar>
    <div class="ml-2 text-white">
      <div class="agent-header-company">{{ companyName }}</div>
      <div>
        <h6 class="agent-header-agent">{{ displayName }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    companyName: {
      type: String,
      required: false
    },
    displayName: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyInfo: 'company'
    }),
    agent() {
      let agentName = null
      if (this.activeUserInfo && this.activeUserInfo.firstname) {
        agentName = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          agentName = `${agentName} ${this.activeUserInfo.lastname}`
        }
      } else {
        agentName = this.activeUserInfo.displayName ? this.activeUserInfo.displayName : ''
      }
      return agentName ? agentName.toUpperCase() : agentName
    },
    company() {
      let companyName = null
      if (this.companyInfo && this.companyInfo.name) {
        companyName = this.companyInfo.name
      }
      return companyName ? companyName.toUpperCase() : companyName
    }
  }
}
</script>
<style lang="scss" scoped>
.agent-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 65px;
  &-company {
    font-weight: 100;
    font-size: 0.9rem;
  }
  &-agent {
    font-weight: 600;
    font-size: 1.3rem;
    color: white;
  }
}
</style>
