var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visitorId != null
    ? _c(
        "div",
        { staticClass: "video-screen-maximized" },
        [
          _vm.showSettings
            ? _c("audio-video-settings", {
                attrs: {
                  show: _vm.showSettings,
                  "selected-audio": _vm.selectedAudio,
                  "selected-video": _vm.selectedVideo,
                },
                on: {
                  close: function ($event) {
                    return _vm.onCloseSettings()
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "video-screen-maximized-navbar" }, [
            _c(
              "div",
              { staticClass: "video-screen-maximized-navbar-left-container" },
              [
                _c("agent-modal-header", {
                  attrs: {
                    "company-name": _vm.companyName,
                    "display-name": _vm.displayName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "video-screen-maximized-navbar-right-container" },
              [
                _c(
                  "div",
                  {
                    staticClass: "video-screen-maximized-navbar-button mr-2",
                    on: {
                      click: function ($event) {
                        _vm.showSettings = true
                      },
                    },
                  },
                  [
                    _c("settings-icon", {
                      staticClass:
                        "video-screen-maximized-navbar-icon pa-icon-default",
                      attrs: { width: 16, height: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "video-screen-maximized-navbar-communications-control mr-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "video-screen-maximized-navbar-communications-control-button",
                        class: {
                          "pa-bk-active": _vm.messageModes.includes("chat"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "change-communication-control",
                              "chat"
                            )
                          },
                        },
                      },
                      [
                        _c("chat-icon", {
                          class: {
                            "pa-icon-default":
                              !_vm.messageModes.includes("chat"),
                          },
                          attrs: { width: 16, height: 16 },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "video-screen-maximized-navbar-communications-control-button",
                        class: {
                          "pa-bk-active": _vm.messageModes.includes("audio"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "change-communication-control",
                              "audio"
                            )
                          },
                        },
                      },
                      [
                        _c("phone-icon", {
                          class: {
                            "pa-icon-default":
                              !_vm.messageModes.includes("audio"),
                          },
                          attrs: { width: 16, height: 16 },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "video-screen-maximized-navbar-communications-control-button",
                        class: {
                          "pa-bk-active": _vm.messageModes.includes("video"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "change-communication-control",
                              "video"
                            )
                          },
                        },
                      },
                      [
                        _c("video-call-icon", {
                          class: {
                            "pa-icon-default":
                              !_vm.messageModes.includes("video"),
                          },
                          attrs: { width: 16, height: 16 },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "video-screen-maximized-navbar-button pa-bk-danger mr-4",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-communication-control")
                      },
                    },
                  },
                  [
                    _c("phone-icon", {
                      staticClass: "video-screen-maximized-navbar-icon",
                      attrs: { width: 20, height: 20 },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }