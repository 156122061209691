<template>
  <div class="flex flex-col audio-video-settings audio-video-settings-av-settings">
    <div
      class="audio-video-settings-av-settings-inner"
      :class="{
        'full-size': !isBlurOption
      }"
    >
      <!-- <video v-show="isCameraOn" ref="videoCam" id="video-cam" width="300" height="187" autoplay class="video"></video> -->
      <video v-show="isCameraOn" ref="videoCam" id="video-cam" autoplay class="video"></video>

      <div v-if="!isCameraOn" class="audio-video-settings-no-camera flex justify-center items-center">
        <div class="audio-video-settings-no-camera-container flex justify-center items-center relative">
          <settings-video-cam-off color="#ffffff" :width="300" :height="300" style="position: absolute"> </settings-video-cam-off>
        </div>
      </div>
    </div>

    <div v-if="isBlurOption" class="flex flex-row align-items-center py-2 audio-video-settings-av-settings-footer">
      <magic-icon class="ml-2" color="#b2b2b2" :width="16" :height="16"></magic-icon>
      <span class="ml-2 fw-600">Blur your background</span>
      <vs-switch color="#275D73" class="mr-2" style="margin-left: auto" v-model="blurBackground" />
    </div>
  </div>
</template>

<script>
import MagicIcon from '../../../components/icons/MagicIcon.vue'
import SettingsVideoCamOff from '../../../components/icons/SettingsVideoCamOff.vue'

export default {
  components: {
    MagicIcon,
    SettingsVideoCamOff
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    isBlurOption: {
      type: Boolean,
      required: false,
      default: true
    },
    videoStreamProps: {
      required: true
    },
    volumeLevelProps: {
      required: true
    }
  },
  data() {
    return {
      defaultVideoBackground: require('@/assets/images/embed/video-background.png'),
      volume: 30,
      microphoneOptions: [],
      cameraOptions: [],
      blurBackground: false,
      options: [],
      hideCamera: false,
      videoStream: null,
      loaded: false
    }
  },
  computed: {
    isMicrophoneOn() {
      return this.selectedAudio && this.selectedAudio.value !== 'microphone-off'
    },
    isCameraOn() {
      return this.selectedVideo && this.selectedVideo.value !== 'camera-off'
    }
  },
  watch: {
    videoStreamProps(value) {
      this.videoStream = value
      this.$refs.videoCam.srcObject = value
    },
    volumeLevelProps() {
      const volumeLevel = this.volumeLevelProps / 100

      this.$refs.videoCam.volume = volumeLevel
    },
    show() {
      if (this.show) {
        navigator.mediaDevices
          .getUserMedia({ audio: { echoCancellation: true }, video: true })
          .then(this.enumerateDevices)
          .then(this.gotDevices)
          .then(this.onSelectDefaultVideo)
          .catch(this.errorCallback)
      } else {
        this.stopAllTracks()
        this.loaded = false
      }
    }
  },
  methods: {
    toggleMicrophone() {
      if (this.isMicrophoneOn) {
        const mic = this.microphoneOptions.find((x) => x.value === 'microphone-off')
        this.onAudioInputSelected(mic)
      } else {
        const mic = this.microphoneOptions.find((x) => x.value !== 'microphone-off')
        if (mic) {
          this.onAudioInputSelected(mic)
        }
      }
    },
    toggleCamera() {
      if (this.isCameraOn) {
        const camera = this.cameraOptions.find((x) => x.value === 'camera-off')
        this.onVideoInputSelected(camera)
      } else {
        const camera = this.cameraOptions.find((x) => x.value !== 'camera-off')
        if (camera) {
          this.onVideoInputSelected(camera)
        }
      }
    },
    onSelectDefaultVideo() {
      this.loaded = true
      const selectedVideo = this.selectedVideo || this.cameraOptions[0]
      if (selectedVideo) {
        this.onVideoInputSelected(selectedVideo)
      }
    },
    stopAudioTracks() {
      if (this.videoStream) {
        this.videoStream.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopVideoTracks() {
      if (this.videoStream) {
        this.videoStream.getVideoTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopAllTracks() {
      if (this.videoStream) {
        if (typeof this.videoStream.getTracks === 'undefined') {
          this.videoStream.stop()
        } else {
          this.videoStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          this.videoStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.videoStream = null
      }
      this.$refs.videoCam.srcObject = null
    },
    enumerateDevices(stream) {
      this.videoStream = stream
      return navigator.mediaDevices.enumerateDevices()
    },
    onMicrophoneClicked() {
      this.hideCamera = !this.hideCamera
    },
    onMicrophoneSelectBlur() {
      this.hideCamera = false
    },
    onAudioInputSelected(option) {
      this.hideCamera = false
      if (option && option.value && option.value !== 'microphone-off') {
        this.stopAudioTracks()
        this.$serverBus.$emit('audio-input-selected', option)
        const constraints = {}
        constraints.audio = { deviceId: option.value, echoCancellation: true }
        if (this.isCameraOn) {
          constraints.video = { deviceId: this.selectedVideo.value, width: 300, height: 187 }
        } else {
          constraints.video = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            this.$refs.videoCam.srcObject = stream
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
      } else {
        this.stopAudioTracks()
        this.$serverBus.$emit('audio-input-selected', option)
      }
    },
    onVideoInputSelected(option) {
      if (option && option.value && option.value !== 'camera-off') {
        this.stopVideoTracks()
        const constraints = {}
        constraints.video = { deviceId: option.value, echoCancellation: true }
        if (this.isMicrophoneOn) {
          constraints.audio = { deviceId: this.selectedAudio.value, width: 300, height: 187 }
        } else {
          constraints.audio = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            this.$refs.videoCam.srcObject = stream
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
        this.$serverBus.$emit('video-input-selected', option)
      } else {
        this.stopVideoTracks()
        this.$serverBus.$emit('video-input-selected', option)
      }
    },
    gotDevices(deviceInfos) {
      this.microphoneOptions = []
      this.cameraOptions = []
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i]
        const option = {}
        option.value = deviceInfo.deviceId
        if (deviceInfo.kind === 'audioinput') {
          option.text = deviceInfo.label || `Microphone ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'audiooutput') {
          option.text = deviceInfo.label || `Speaker ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `Camera ${this.options.length + 1}`
          this.cameraOptions.push(option)
        }
      }
      this.microphoneOptions.unshift({ text: 'Microphone off', value: 'microphone-off' })
      this.cameraOptions.unshift({ text: 'Camera off', value: 'camera-off' })
    },
    errorCallback(e) {
      /* eslint-disable no-console */
      console.log(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.audio-video-settings {
  &-no-camera {
    width: 100%;
    height: 100%;

    &-container {
      width: 300px;
      height: 300px;
      &-message {
        width: 190px;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 40px;
        text-align: center;
        color: #12598d;
      }
    }
  }
  .video {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
  }
  span {
    font-size: 15px;
    line-height: 19px;
    color: #12598d;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .bk-select {
    background-color: #e6ecf1 !important;
    border-radius: 6px;
  }
  .bk-transparent {
    background-color: transparent !important;
  }
  .fw-600 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #b2b2b2;
  }
  .align-items-center {
    align-items: center !important;
  }
  .ht-36 {
    height: 36px !important;
  }
  &-icon {
    margin-left: -1px;
  }
  &-button {
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  &-communication-button {
    width: 48px;
    height: 30px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
  }
  .on {
    background: #3b86f7;
  }
  .off {
    background: #ffffff;
  }
  &-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: rgba(var(--vs-secondary), 1);
    text-transform: uppercase;
  }
  &-av-settings {
    height: 100%;
    width: 100%;
    background: #e6ecf1;
    border-radius: 6px;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-inner {
      height: calc(100% - 40px);

      &.full-size {
        height: 100%;

        .video {
          border-radius: 6px;
        }
      }
    }
  }
  &-av-settings-background {
    height: 100%;
    width: 100%;
  }
  &-av-settings-footer {
    padding: 2px;
    height: 40px;
  }
}
</style>
