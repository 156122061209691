var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.screen === _vm.SCREEN.MAXIMIZED
          ? "video-audio-chat-video-visitor-maximized"
          : "",
    },
    [
      _vm.screen === _vm.SCREEN.MINIMIZED && !_vm.hasVideoStream
        ? _c("div", [
            _c("div", { staticClass: "video-audio-chat-audio" }, [
              _c("div", { staticClass: "video-audio-chat-audio-header" }, [
                _c(
                  "div",
                  [_c("audio-icon", { attrs: { width: 199, height: 79 } })],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          class:
            _vm.screen === _vm.SCREEN.MINIMIZED
              ? "video-audio-chat-video-visitor-video-minimized"
              : "video-audio-chat-video-visitor-video-maximized mr-4",
          style: _vm.hideVideoStyle,
        },
        [_c("video", { ref: "remoteVideo", attrs: { autoplay: "" } })]
      ),
      _c(
        "div",
        {
          class:
            _vm.screen === _vm.SCREEN.MINIMIZED
              ? "video-audio-chat-video-agent-video-minimized"
              : "video-audio-chat-video-agent-video-maximized ml-4 mr-4",
          style: _vm.hideVideoStyle,
        },
        [
          _c("video", {
            ref: "localVideo",
            staticClass: "video",
            attrs: { id: "local-video", muted: "", autoplay: "" },
            domProps: { muted: true },
          }),
        ]
      ),
      _vm.screen === _vm.SCREEN.MAXIMIZED
        ? _c("visitor-chats", {
            staticClass: "video-audio-chat-chat-messages",
            attrs: {
              "visitor-id": _vm.visitorId,
              visitor: _vm.visitor,
              "message-modes": _vm.messageModes,
              screen: _vm.screen,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }