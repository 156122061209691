var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.meeting
    ? _c(
        "div",
        {
          staticClass:
            "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center px-3",
          class: {
            "bg-img_gray": _vm.step === 2 && (_vm.isCall || _vm.isAgent),
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "meeting-container",
              class: {
                "meeting-container_darken": _vm.step === 2,
                "meeting-container_calling":
                  _vm.step > 2 || (_vm.step === 2 && _vm.isAgent),
              },
            },
            [
              _vm.step === 1
                ? _c("div", { staticClass: "meeting-inner" }, [
                    _c(
                      "div",
                      { staticClass: "video-block" },
                      [
                        _c("Video", {
                          attrs: {
                            "selected-audio": _vm.selectedAudio,
                            "selected-video": _vm.selectedVideo,
                            "video-stream-props": _vm.videoStreamProps,
                            "volume-level-props": _vm.volumeLevel,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "call-settings" }, [
                      _c("div", { staticClass: "call-settings__header" }, [
                        _c(
                          "div",
                          { staticClass: "call-settings__header__name" },
                          [_vm._v(_vm._s(_vm.meeting.companyName))]
                        ),
                        _vm.isAgent
                          ? _c(
                              "div",
                              { staticClass: "call-settings__header__details" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.meeting.message ||
                                        "The visitor did not leave a message"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "call-settings__header__details call-settings__header__details-visitor",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.bookedSlotInfo) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "call-settings__permissions" },
                        [
                          _c("Settings", {
                            attrs: {
                              "selected-audio": _vm.selectedAudio,
                              "selected-video": _vm.selectedVideo,
                            },
                            on: {
                              "video-stream-props": function ($event) {
                                _vm.videoStreamProps = $event
                              },
                              "video-cam-volume-props": function ($event) {
                                _vm.volumeLevel = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "call-settings__actions" }, [
                        _vm.isAgent
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-action",
                                on: { click: _vm.startMeeting },
                              },
                              [_vm._v("START MEETING")]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn-action",
                                on: { click: _vm.joinMeeting },
                              },
                              [_vm._v("JOIN MEETING")]
                            ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "mt-2 btn-action btn-action_transparent",
                          },
                          [_vm._v("CANCEL")]
                        ),
                      ]),
                    ]),
                  ])
                : _vm.step === 2
                ? _c(
                    "div",
                    {
                      staticClass: "meeting-inner",
                      class: {
                        "meeting-inner_loading": _vm.isAgent,
                      },
                    },
                    [
                      !_vm.isAgent
                        ? [
                            _c(
                              "button",
                              { staticClass: "btn-close" },
                              [
                                _c("PhoneIcon", {
                                  staticClass: "btn-close__icon",
                                  attrs: { width: 24, height: 24 },
                                }),
                              ],
                              1
                            ),
                            _c("VsProgress", {
                              staticClass: "loader-progress",
                              attrs: {
                                indeterminate: "",
                                color: "#12598D",
                                height: "8",
                              },
                            }),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "video-block" },
                        [
                          _c("Video", {
                            attrs: {
                              "selected-audio": _vm.selectedAudio,
                              "selected-video": _vm.selectedVideo,
                              "is-blur-option": false,
                              "video-stream-props": _vm.videoStreamProps,
                              "volume-level-props": _vm.volumeLevel,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "call-settings" }, [
                        _vm.isAgent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "call-settings__agent-waiting-block",
                              },
                              [
                                !_vm.isCusotmerReady
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "call-settings__agent-waiting-block__name",
                                        },
                                        [
                                          _vm._v(
                                            "Waiting for " +
                                              _vm._s(_vm.meeting.companyName)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "call-settings__agent-waiting-block__details",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.meeting.message ||
                                                "The visitor did not leave a message"
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._m(0),
                                    ]
                                  : _vm.isCusotmerReady && !_vm.isCall
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "call-settings__agent-waiting-block__name",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.meeting.companyName) +
                                              " is already in the waiting area"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-action call-settings__agent-waiting-block__btn-allow",
                                          on: {
                                            click: function ($event) {
                                              _vm.isCall = true
                                            },
                                          },
                                        },
                                        [_vm._v("ALLOW")]
                                      ),
                                    ]
                                  : _vm.isCall && !_vm.isCameraOn
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "call-settings__agent-waiting-block__name",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.meeting.companyName)
                                          ),
                                        ]
                                      ),
                                      _c("AudioIcon", {
                                        staticClass:
                                          "call-settings__audio-icon",
                                        attrs: { width: 186, height: 73 },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              { staticClass: "call-settings__waiting-title" },
                              [
                                _vm._v(
                                  "Hi, I'll be here for you in a minute and look forward to see you!"
                                ),
                              ]
                            ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm.isCall || (_vm.step === 2 && _vm.isAgent)
            ? _c("AudioVideoCallModal", {
                attrs: {
                  visitorId: _vm.visitorId,
                  visitor: _vm.visitor,
                  "message-mode": _vm.messageMode,
                  "message-modes": _vm.messageModes,
                  "audio-video-started": _vm.audioVideoStarted,
                  "local-stream": _vm.localStream,
                  "remote-stream": _vm.remoteStream,
                  "selected-audio": _vm.selectedAudio,
                  "selected-video": _vm.selectedVideo,
                  "volume-level": _vm.volumeLevel,
                  "company-name": _vm.meeting.companyName,
                  "display-name": _vm.meeting.displayName,
                },
              })
            : _vm._e(),
          _c("AudioVideoSettings", {
            attrs: {
              show: _vm.showSettings,
              "selected-audio": _vm.selectedAudio,
              "selected-video": _vm.selectedVideo,
            },
            on: { close: _vm.onCloseSettings },
          }),
          _vm.step === 1
            ? _c("div", { staticClass: "banner" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/logo/face-to-face-logo.png"),
                    alt: "powered by Let’s Connect",
                  },
                }),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-grid" }, [
      _c("div"),
      _c("div"),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }