var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messageModes.includes("chat")
    ? _c(
        "div",
        { staticClass: "visitor-chats" },
        [
          _c("chat-log", {
            staticClass: "w-full",
            attrs: {
              "visitor-id": _vm.visitorId,
              visitor: _vm.visitor,
              maxHeight: _vm.screenHeight,
              "is-inside-modal": true,
            },
          }),
          _c("div", { staticClass: "visitor-chats-tools flex padding-2" }, [
            _c(
              "div",
              {
                staticClass: "flex flex-row",
                staticStyle: {
                  "justify-content": "flex-start",
                  "flex-grow": "1",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "visitor-chats-tools-button" },
                  [
                    _c("clip-icon", {
                      staticClass: "visitor-chats-container-icon",
                      attrs: { width: 20, height: 20 },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex flex-row",
                staticStyle: {
                  "justify-content": "flex-end",
                  "flex-grow": "1",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "visitor-chats-tools-button" },
                  [
                    _c("more-icon", {
                      staticClass: "visitor-chats-container-icon",
                      attrs: { width: 20, height: 20 },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm.canChatWithVisitor
            ? _c(
                "div",
                { staticClass: "visitor-chats-container flex padding-2" },
                [
                  _c("send-chat-message", {
                    attrs: {
                      "visitor-id": _vm.visitorId,
                      visitor: _vm.visitor,
                      "is-modal": true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }