<template>
  <connect-modal class="audio-video-settings" :show="show" :width="780" :height="384" bkcolor="#F7F7F7">
    <div class="flex flex-row space-between">
      <div>
        <settings-icon class="audio-video-settings-icon pa-icon-default" :width="20" :height="20"></settings-icon>
      </div>
      <div class="audio-video-settings-button" style="margin-left: auto" @click="$emit('close')">
        <close-circle-icon class="audio-video-settings-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
    </div>
    <div class="audio-video-settings-heading">{{ $t('vue.videoAudioSettings') }}</div>
    <div class="flex flex-row">
      <div class="flex flex-col audio-video-settings-av-settings">
        <div>
          <video v-show="isCameraOn" ref="videoCam" id="video-cam" width="300" height="187" autoplay class="video"></video>
          <div v-if="!isCameraOn" class="audio-video-settings-no-camera flex justify-center items-center">
            <div class="audio-video-settings-no-camera-container flex justify-center items-center relative">
              <settings-video-cam-off color="#ffffff" :width="186" :height="186" style="position: absolute"> </settings-video-cam-off>
            </div>
          </div>
        </div>
        <div class="flex flex-row align-items-center audio-video-settings-av-settings-footer">
          <magic-icon class="ml-2" color="#b2b2b2" :width="16" :height="16"></magic-icon>
          <span class="ml-2 fw-600">{{ $t('vue.changeVirtualBackground') }}</span>
          <vs-switch color="#275D73" class="mr-2" style="margin-left: auto" v-model="blurBackground" />
        </div>
      </div>
      <div class="ml-4 flex flex-col justify-center">
        <div>
          <div class="mt-2">
            <span>{{ $t('vue.microphoneAndLoudspeaker') }}</span>
          </div>
          <div class="mt-2 flex flex-row flex-grow bk-select" v-if="microphoneOptions.length > 0">
            <div
              :class="isMicrophoneOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'"
              @click="toggleMicrophone"
            >
              <microphone-icon
                v-if="selectedAudio && selectedAudio.value !== 'microphone-off'"
                class="audio-video-settings-communication-icon"
                :width="16"
                :height="16"
              ></microphone-icon>
              <microphone-off-icon
                v-if="!selectedAudio || selectedAudio.value === 'microphone-off'"
                class="audio-video-settings-communication-icon"
                :width="16"
                :height="16"
                color="#3B86F7"
              ></microphone-off-icon>
            </div>
            <div class="w-full">
              <connect-select
                :options="microphoneOptions"
                :default="selectedAudio"
                @input-selected="onAudioInputSelected"
                @input-click="onMicrophoneClicked"
                @input-blur="onMicrophoneSelectBlur"
              ></connect-select>
            </div>
          </div>
          <div class="mt-4">
            <span>{{ $t('vue.camera') }}</span>
          </div>
          <div class="mt-2">
            <div class="flex flex-row flex-grow bk-select">
              <div :class="isCameraOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'" @click="toggleCamera">
                <video-call-icon v-if="isCameraOn" class="audio-video-settings-communication-icon" :width="16" :height="16"></video-call-icon>
                <video-call-off-icon v-else class="audio-video-settings-communication-icon" color="#3B86F7" :width="16" :height="16"></video-call-off-icon>
              </div>
              <div class="w-full">
                <connect-select :options="cameraOptions" :default="selectedVideo" @input-selected="onVideoInputSelected" v-show="!hideCamera"></connect-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </connect-modal>
</template>
<script>
import ConnectModal from '../../../components/ConnectModal.vue'
import ConnectSelect from '../../../components/ConnectSelect.vue'
import SettingsIcon from '../../../components/icons/SettingsIcon.vue'
import CloseCircleIcon from '../../../components/icons/CloseCircleIcon.vue'
import MagicIcon from '../../../components/icons/MagicIcon.vue'
import MicrophoneIcon from '../../../components/icons/MicrophoneIcon.vue'
import MicrophoneOffIcon from '../../../components/icons/MicrophoneOffIcon.vue'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
import VideoCallOffIcon from '../../../components/icons/VideoCallOffIcon.vue'
import SettingsVideoCamOff from '../../../components/icons/SettingsVideoCamOff.vue'

export default {
  components: {
    ConnectModal,
    ConnectSelect,
    SettingsIcon,
    CloseCircleIcon,
    MagicIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    VideoCallIcon,
    VideoCallOffIcon,
    SettingsVideoCamOff
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      defaultVideoBackground: require('@/assets/images/embed/video-background.png'),
      volume: 30,
      microphoneOptions: [],
      cameraOptions: [],
      blurBackground: false,
      options: [],
      hideCamera: false,
      videoStream: null,
      loaded: false
    }
  },
  computed: {
    isMicrophoneOn() {
      return this.selectedAudio && this.selectedAudio.value !== 'microphone-off'
    },
    isCameraOn() {
      return this.selectedVideo && this.selectedVideo.value !== 'camera-off'
    }
  },
  watch: {
    volume() {
      const volumeLevel = this.volume / 100
      this.$refs.videoCam.volume = volumeLevel
      this.$serverBus.$emit('on-volume-changed', { level: volumeLevel })
    },
    show() {
      if (this.show) {
        navigator.mediaDevices
          .getUserMedia({ audio: { echoCancellation: true }, video: true })
          .then(this.enumerateDevices)
          .then(this.gotDevices)
          .then(this.onSelectDefaultDevice)
          .catch(this.errorCallback)
      } else {
        this.stopAllTracks()
        this.loaded = false
      }
    }
  },
  methods: {
    toggleMicrophone() {
      if (this.isMicrophoneOn) {
        const mic = this.microphoneOptions.find((x) => x.value === 'microphone-off')
        this.onAudioInputSelected(mic)
      } else {
        const mic = this.microphoneOptions.find((x) => x.value !== 'microphone-off')
        if (mic) {
          this.onAudioInputSelected(mic)
        }
      }
      this.stopAllTracks()
    },
    toggleCamera() {
      if (this.isCameraOn) {
        const camera = this.cameraOptions.find((x) => x.value === 'camera-off')
        this.onVideoInputSelected(camera)
      } else {
        const camera = this.cameraOptions.find((x) => x.value !== 'camera-off')
        if (camera) {
          this.onVideoInputSelected(camera)
        }
      }
      this.stopAllTracks()
    },
    onSelectDefaultDevice() {
      this.loaded = true
      const selectedVideo = this.selectedVideo || this.cameraOptions[0]
      if (selectedVideo) {
        this.onVideoInputSelected(selectedVideo)
      }
      const selectedAudio = this.selectedAudio || this.microphoneOptions[0]
      if (selectedAudio) {
        this.onAudioInputSelected(selectedAudio)
      }
      this.onAudioVideoDefaultSelected()
    },
    stopAudioTracks() {
      if (this.videoStream) {
        this.videoStream.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopVideoTracks() {
      if (this.videoStream) {
        this.videoStream.getVideoTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopAllTracks() {
      if (this.videoStream) {
        if (typeof this.videoStream.getTracks === 'undefined') {
          this.videoStream.stop()
        } else {
          this.videoStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          this.videoStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.videoStream = null
      }
      this.$refs.videoCam.srcObject = null
    },
    enumerateDevices(stream) {
      this.videoStream = stream
      return navigator.mediaDevices.enumerateDevices()
    },
    onMicrophoneClicked() {
      this.hideCamera = !this.hideCamera
    },
    onMicrophoneSelectBlur() {
      this.hideCamera = false
    },
    onAudioVideoDefaultSelected() {
      this.stopAllTracks()
      const constraints = {}
      if (this.isCameraOn) {
        constraints.video = { deviceId: this.selectedVideo.value, width: 300, height: 187 }
      } else {
        constraints.video = false
      }
      if (this.isMicrophoneOn) {
        constraints.audio = { deviceId: this.selectedAudio.value, echoCancellation: true }
      } else {
        constraints.audio = false
      }
      navigator.mediaDevices.getUserMedia(constraints).then(
        (stream) => {
          this.videoStream = stream
          this.$refs.videoCam.srcObject = stream
        },
        (err) => {
          /* eslint-disable no-console */ /* eslint-disable no-console */
          console.log(err)
        }
      )
    },
    onAudioInputSelected(option) {
      this.hideCamera = false
      if (option && option.value && option.value !== 'microphone-off') {
        this.stopAudioTracks()
        this.$serverBus.$emit('audio-input-selected', option)
        const constraints = {}
        constraints.audio = { deviceId: option.value, echoCancellation: true }
        if (this.isCameraOn) {
          constraints.video = { deviceId: this.selectedVideo.value, width: 300, height: 187 }
        } else {
          constraints.video = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            this.$refs.videoCam.srcObject = stream
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
      } else {
        this.stopAudioTracks()
        this.$serverBus.$emit('audio-input-selected', option)
      }
    },
    onVideoInputSelected(option) {
      if (option && option.value && option.value !== 'camera-off') {
        this.stopVideoTracks()
        const constraints = {}
        constraints.video = { deviceId: option.value, width: 300, height: 187 }
        if (this.isMicrophoneOn) {
          constraints.audio = { deviceId: this.selectedAudio.value, echoCancellation: true }
        } else {
          constraints.audio = false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(
          (stream) => {
            this.videoStream = stream
            this.$refs.videoCam.srcObject = stream
          },
          (err) => {
            /* eslint-disable no-console */
            console.log(err)
          }
        )
        this.$serverBus.$emit('video-input-selected', option)
      } else {
        this.stopVideoTracks()
        this.$serverBus.$emit('video-input-selected', option)
      }
    },
    gotDevices(deviceInfos) {
      this.microphoneOptions = []
      this.cameraOptions = []
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i]
        const option = {}
        option.value = deviceInfo.deviceId
        if (deviceInfo.kind === 'audioinput') {
          option.text = deviceInfo.label || `Microphone ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'audiooutput') {
          option.text = deviceInfo.label || `Speaker ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `Camera ${this.options.length + 1}`
          this.cameraOptions.push(option)
        }
      }
      this.microphoneOptions.push({ text: 'Microphone off', value: 'microphone-off' })
      this.cameraOptions.push({ text: 'Camera off', value: 'camera-off' })
    },
    errorCallback(e) {
      /* eslint-disable no-console */
      console.log(e)
    }
  }
}
</script>
<style lang="scss" scoped>
.audio-video-settings {
  &-no-camera {
    width: 300px;
    height: 187px;
    &-container {
      width: 186px;
      height: 186px;
      &-message {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #12598d;
      }
    }
  }
  .video {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
  }
  span {
    font-size: 15px;
    line-height: 19px;
    color: #12598d;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .bk-select {
    background-color: #e6ecf1 !important;
    border-radius: 6px;
  }
  .bk-transparent {
    background-color: transparent !important;
  }
  .fw-600 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #b2b2b2;
  }
  .align-items-center {
    align-items: center !important;
  }
  .ht-36 {
    height: 36px !important;
  }
  &-icon {
    margin-left: -1px;
  }
  &-button {
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  &-communication-button {
    width: 48px;
    height: 30px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
  }
  .on {
    background: #3b86f7;
  }
  .off {
    background: #ffffff;
  }
  &-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: rgba(var(--vs-secondary), 1);
    text-transform: uppercase;
  }
  &-av-settings {
    height: 223px;
    width: 300px;
    background: #e6ecf1;
    border-radius: 6px;
    video {
      object-fit: cover;
    }
  }
  &-av-settings-background {
    height: 187px;
    width: 300px;
  }
  &-av-settings-footer {
    padding: 2px;
  }
}
</style>
